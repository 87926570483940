import * as React from 'react'
import { AvForm, AvField,  AvCheckboxGroup, AvCheckbox } from 'availity-reactstrap-validation';
import { Label, Button, FormGroup, Row, Col } from 'reactstrap';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {useEffect} from "react";

export default function QuestionThree({handleQ, q1Value, handleBack}) {

  const handleSubmit = (event, errors, values) => {

    const criticalErrors = ['reference1', 'refEmail1', 'refContact1', 'declaration',
      'initials', 'confirmDate1'];

    const includesErrors = errors.some((element) => criticalErrors.includes(element));
    if (includesErrors) {
      return;
    }
    handleQ(errors, values, 3)
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <div>
      <AvForm onSubmit={handleSubmit} className="my-custom-form">

        {/*Education Section*/}
        <div style={{ padding: "16px 0px 0px 0px"}}>
          <div style={{ padding: "2px 0px 20px 0px"}}>
            <Typography>
              You must provide references from your two most recent employers.
              Please provide an additional character referee. All will be contacted,
              therefore please inform the referees of the fact that you have used their name.
              If you are unable to provide the required references, please inform your interviewer immediately if
              you do not meet these specifications.
            </Typography>
          </div>
        </div>

        {/*Ref Name 1*/}
        <div className="form-row">
          <Grid container spacing={2}>
            <Grid item={12} style={{width: "100%"}}>
              <div className="form-group">
                <label htmlFor="reference1">Name (current or most recent employer):</label>
                <AvField
                  name="reference1"
                  id="reference1"
                  type="text"
                  errorMessage="Invalid"
                  validate={{
                    required: { value: true },
                    minLength: { value: 3 },
                    maxLength: { value: 72 },
                  }}
                  className="custom-input"
                />
              </div>
            </Grid>
          </Grid>
        </div>

        {/*Ref Email and Contact 1*/}
        <div className="form-row">
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <div>
                <div className="form-group">
                  <label htmlFor="refEmail1">Email address</label>
                  <AvField
                    name="refEmail1"
                    id="refEmail1"
                    type="text"
                    errorMessage="Invalid email"
                    validate={{
                      required: { value: true },
                      minLength: { value: 3 },
                      maxLength: { value: 200 },
                    }}
                    className="custom-input"
                  />
                </div>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div>
                <div className="form-group">
                  <label htmlFor="refContact1">Contact Number</label>
                  <AvField
                    name="refContact1"
                    id="refContact1"
                    type="text"
                    errorMessage="Invalid Contact Number"
                    validate={{
                      required: { value: true },
                      pattern: { value: '^[0-9]+$' },
                      minLength: { value: 3 },
                      maxLength: { value: 16 },
                    }}
                    className="custom-input"
                  />
                </div>
              </div>
            </Grid>
          </Grid>
        </div>

        {/*Ref Job title 1*/}
        <div className="form-row">
          <Grid container spacing={2}>
            <Grid item={8} style={{width: "100%"}}>
              <div className="form-group">
                <label htmlFor="refAddress1">Physical Address:</label>
                <AvField
                  name="refAddress1"
                  id="refAddress1"
                  type="text"
                  errorMessage="Invalid"
                  validate={{
                    required: { value: true },
                    minLength: { value: 3 },
                    maxLength: { value: 72 },
                  }}
                  className="custom-input"
                />
              </div>
            </Grid>
            <Grid item={4} style={{width: "100%"}}>
              <div className="form-group">
                <label htmlFor="referenceTitle1">Job Title:</label>
                <AvField
                  name="referenceTitle1"
                  id="referenceTitle1"
                  type="text"
                  errorMessage="Invalid"
                  validate={{
                    required: { value: true },
                    minLength: { value: 3 },
                    maxLength: { value: 72 },
                  }}
                  className="custom-input"
                />
              </div>
            </Grid>
          </Grid>
        </div>

        <hr style={{border: "2px solid #000", margin: "24px 0px 24px 0px"}} />

        {/*Ref Name 2*/}
        <div className="form-row">
          <Grid container spacing={2}>
            <Grid item={12} style={{width: "100%"}}>
              <div className="form-group">
                <label htmlFor="reference2">Name (previous employer to one above):</label>
                <AvField
                  name="reference2"
                  id="reference2"
                  type="text"
                  errorMessage="Invalid"
                  validate={{
                    required: { value: true },
                    minLength: { value: 3 },
                    maxLength: { value: 72 },
                  }}
                  className="custom-input"
                />
              </div>
            </Grid>
          </Grid>
        </div>

        {/*Ref Email and Contact 2*/}
        <div className="form-row">
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <div>
                <div className="form-group">
                  <label htmlFor="refEmail2">Email address</label>
                  <AvField
                    name="refEmail2"
                    id="refEmail2"
                    type="text"
                    errorMessage="Invalid email"
                    validate={{
                      required: { value: true },
                      minLength: { value: 3 },
                      maxLength: { value: 200 },
                    }}
                    className="custom-input"
                  />
                </div>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div>
                <div className="form-group">
                  <label htmlFor="refContact2">Contact Number</label>
                  <AvField
                    name="refContact2"
                    id="refContact2"
                    type="text"
                    errorMessage="Invalid Contact Number"
                    validate={{
                      required: { value: true },
                      pattern: { value: '^[0-9]+$' },
                      minLength: { value: 3 },
                      maxLength: { value: 16 },
                    }}
                    className="custom-input"
                  />
                </div>
              </div>
            </Grid>
          </Grid>
        </div>

        {/*Ref Job title 2*/}
        <div className="form-row">
          <Grid container spacing={2}>
            <Grid item={8} style={{width: "100%"}}>
              <div className="form-group">
                <label htmlFor="refAddress2">Physical Address:</label>
                <AvField
                  name="refAddress2"
                  id="refAddress2"
                  type="text"
                  errorMessage="Invalid"
                  validate={{
                    required: { value: true },
                    minLength: { value: 3 },
                    maxLength: { value: 72 },
                  }}
                  className="custom-input"
                />
              </div>
            </Grid>
            <Grid item={4} style={{width: "100%"}}>
              <div className="form-group">
                <label htmlFor="referenceTitle2">Job Title:</label>
                <AvField
                  name="referenceTitle2"
                  id="referenceTitle2"
                  type="text"
                  errorMessage="Invalid"
                  validate={{
                    required: { value: true },
                    minLength: { value: 3 },
                    maxLength: { value: 72 },
                  }}
                  className="custom-input"
                />
              </div>
            </Grid>
          </Grid>
        </div>

        <hr style={{border: "2px solid #000", margin: "24px 0px 24px 0px"}} />

        {/*Ref Name 3*/}
        <div className="form-row">
          <Grid container spacing={2}>
            <Grid item={12} style={{width: "100%"}}>
              <div className="form-group">
                <label htmlFor="refCharacter">Name (Character reference):</label>
                <AvField
                  name="refCharacter"
                  id="refCharacter"
                  type="text"
                  errorMessage="Invalid"
                  validate={{
                    required: { value: true },
                    minLength: { value: 3 },
                    maxLength: { value: 72 },
                  }}
                  className="custom-input"
                />
              </div>
            </Grid>
          </Grid>
        </div>

        {/*Ref Email and Contact 3*/}
        <div className="form-row">
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <div>
                <div className="form-group">
                  <label htmlFor="refCharacterEmail">Email address</label>
                  <AvField
                    name="refCharacterEmail"
                    id="refCharacterEmail"
                    type="text"
                    errorMessage="Invalid email"
                    validate={{
                      required: { value: true },
                      minLength: { value: 3 },
                      maxLength: { value: 200 },
                    }}
                    className="custom-input"
                  />
                </div>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div>
                <div className="form-group">
                  <label htmlFor="refCharacterContact">Contact Number</label>
                  <AvField
                    name="refCharacterContact"
                    id="refCharacterContact"
                    type="text"
                    errorMessage="Invalid contact number - enter digits only"
                    validate={{
                      required: { value: true },
                      pattern: { value: '^[0-9]+$' },
                      minLength: { value: 3 },
                      maxLength: { value: 16 },
                    }}
                    className="custom-input"
                  />
                </div>
              </div>
            </Grid>
          </Grid>
        </div>

        {/*Ref Job title 2*/}
        <div className="form-row">
          <Grid container spacing={2}>
            <Grid item={8} style={{width: "100%"}}>
              <div className="form-group">
                <label htmlFor="refCharaterAddress">Physical Address:</label>
                <AvField
                  name="refCharaterAddress"
                  id="refCharaterAddress"
                  type="text"
                  errorMessage="Invalid"
                  validate={{
                    required: { value: true },
                    minLength: { value: 3 },
                    maxLength: { value: 72 },
                  }}
                  className="custom-input"
                />
              </div>
            </Grid>
            <Grid item={4} style={{width: "100%"}}>
              <div className="form-group">
                <label htmlFor="refCharacterTitle">Relationship To you:</label>
                <AvField
                  name="refCharacterTitle"
                  id="refCharacterTitle"
                  type="text"
                  errorMessage="Invalid"
                  validate={{
                    required: { value: true },
                    minLength: { value: 3 },
                    maxLength: { value: 72 },
                  }}
                  className="custom-input"
                />
              </div>
            </Grid>
          </Grid>
        </div>

        {/*Confidential Declaration*/}
        <div style={{ padding: "16px 0px 0px 0px"}}>
          <div>
            <Typography>
              <h3>Confidential Declaration</h3>
            </Typography>
          </div>
          <div style={{ padding: "2px 0px 20px 0px"}}>
            <Typography className="terms">
              Before you can be considered for appointment in a position of trust with Excel Health Care we need to be
              satisfied about your character and suitability.
              Please read the following notes carefully before completing this declaration Form. If you require further
              information, please contact
              All enquires will be treated in confidence.
            </Typography>
            <br/>
            <Typography className="terms">
              Excel Healthcare aims to promote equality of opportunity and is committed to treating all applicants for
              positions fairly and on merit regardless of race, gender, marital status, religion, disability,
              sexual orientation, age or offending history. We undertake not to discriminate unfairly against based
              on criminal conviction or other information declared.
              Prior to making a final decision concerning your application, we shall discuss with you any
              information declared by you that we believe has a bearing on your suitability for the position.
              If we do not raise information with you, this is because we do not believe that it should be considered.
              In that event, you remain free to discuss any of that information or any other matter that you wish
              to raise. As part of assessing your application, we will only consider relevant criminal record and other
              information declared.
            </Typography>
            <br/>
            <Typography className="terms">
              EU General Data Protection Regulation (GDPR) which replaces The Data Protection Act 1998 requires us to
              provide you with certain information and to obtain your consent before processing sensitive data about
              you. Processing includes: obtaining, recording, holding, disclosing destruction and retaining information.
              Sensitive personal data includes any of the following information: criminal offences, criminal
              convictions, criminal proceedings, disposal or sentence. The information that you provide in this
              Declaration Form will be processed in accordance with Data Protection Act 1998, and will only be used for
              determining your application for this position.

              This Declaration Form will be kept securely and in confidence, and access to it will be restricted to
              designated persons within Excel Healthcare and other persons who need to see it as part of the selection
              process and who are authorised to do so.
              Please ensure that you read the Guidance Notes for Applicants that accompanied your application
              form carefully before completing this Declaration Form. They provide you with further and more detailed
              information concerning how your application will be processed, and include details for which information
              about you will be processed, the persons to whom it will be disclosed and the checks that will be
              undertaken to verify the information provided before you are offered a position if your application is
              successful.
            </Typography>
            <br/>
            <Typography gutterBottom style={{fontWeight: "bold"}}>
              The position for which you have applied is exempted from the Rehabilitation of Offenders Act 1974.
              This means that you must declare all criminal convictions, including those that would otherwise be
              considered spent.
              Except for question 5, answering Yes to any of the questions below will not necessarily bar you
              from appointment. This will depend on the nature of the position for which you are applying and the
              circumstances.
            </Typography>
          </div>
        </div>

        {/*Criminal Declaration*/}
        {/*Q1*/}
        <div className="form-row">
          <Grid container spacing={2}>
            <Grid item={12} style={{width: "100%"}}>
              <div className="form-group">
                <label htmlFor="criminalQ1">1. Are you currently bound over or have you ever been convicted of any
                  offence by a court or court-martial in the United Kingdom or in any other country?
                  If YES, please include details of the order bounding you over and/or the nature of the offence,
                  the penalty, sentence or order of the court and the date and place of the Court hearing.
                </label>
                <AvField
                  name="criminalQ1"
                  id="criminalQ1"
                  type="text"
                  errorMessage="Invalid"
                  validate={{
                    required: { value: true },
                    minLength: { value: 2 },
                    maxLength: { value: 100 },
                  }}
                  className="custom-input"
                />
              </div>
            </Grid>
          </Grid>
        </div>

        {/*Q2*/}
        <div className="form-row">
          <Grid container spacing={2}>
            <Grid item={12} style={{width: "100%"}}>
              <div className="form-group">
                <label htmlFor="criminalQ2">2. Have you ever received a police caution, reprimand or final warning?
                  If YES, please include details of the caution, reprimand or final warning, the date and reason
                </label>
                <AvField
                  name="criminalQ2"
                  id="criminalQ2"
                  type="text"
                  errorMessage="Invalid"
                  validate={{
                    required: { value: true },
                    minLength: { value: 2 },
                    maxLength: { value: 100 },
                  }}
                  className="custom-input"
                />
              </div>
            </Grid>
          </Grid>
        </div>

        {/*Q3*/}
        <div className="form-row">
          <Grid container spacing={2}>
            <Grid item={12} style={{width: "100%"}}>
              <div className="form-group">
                <label htmlFor="criminalQ3">3. Have you been charged with any offence in the United Kingdom or in any
                  other country that has not yet been disposed of?
                  Please note: you must inform us immediately if you are charged with any offence in the United Kingdom
                  or in any other country after you complete this form and before taking up any position offered to you.
                  You do not need to tell us if you are charged with parking offences.
                </label>
                <AvField
                  name="criminalQ3"
                  id="criminalQ3"
                  type="text"
                  errorMessage="Invalid"
                  validate={{
                    required: { value: true },
                    minLength: { value: 2 },
                    maxLength: { value: 100 },
                  }}
                  className="custom-input"
                />
              </div>
            </Grid>
          </Grid>
        </div>

        {/*Q4*/}
        <div className="form-row">
          <Grid container spacing={2}>
            <Grid item={12} style={{width: "100%"}}>
              <div className="form-group">
                <label htmlFor="criminalQ4">4. Have you ever been dismissed by reason of misconduct from any employment
                  office or other position previously held by you?, If YES, please include details of the dismissal
                </label>
                <AvField
                  name="criminalQ4"
                  id="criminalQ4"
                  type="text"
                  errorMessage="Invalid"
                  validate={{
                    required: { value: true },
                    minLength: { value: 2 },
                    maxLength: { value: 100 },
                  }}
                  className="custom-input"
                />
              </div>
            </Grid>
          </Grid>
        </div>

        {/*Q5*/}
        <div className="form-row">
          <Grid container spacing={2}>
            <Grid item={12} style={{width: "100%"}}>
              <div className="form-group">
                <label htmlFor="criminalQ5">5. Are you subject to any other prohibition, limitation, or restrictions
                  that mean we are unable to consider you for the position for which you are applying?
                </label>
                <AvField
                  name="criminalQ5"
                  id="criminalQ5"
                  type="text"
                  errorMessage="Invalid"
                  validate={{
                    required: { value: true },
                    minLength: { value: 2 },
                    maxLength: { value: 100 },
                  }}
                  className="custom-input"
                />
              </div>
            </Grid>
          </Grid>
        </div>

        {/*Declaration Confirmation*/}
        {/*<div className="form-row">*/}
        {/*  <Grid container spacing={2}>*/}
        {/*    <Grid item xs={12} style={{width: "100%"}}>*/}
        {/*      <div className="form-group">*/}
        {/*        <AvCheckboxGroup name="declaration" label="Declaration" required>*/}
        {/*          <AvCheckbox customInput*/}
        {/*                      label="I confirm that the information given above is complete and correct, and that I*/}
        {/*                      understand that any incomplete, untrue or misleading information given to the employer*/}
        {/*                      will entitle the employer to reject my application, withdraw any employment offer made,*/}
        {/*                      or, if I am employed, dismiss me without notice" value="declaration1CorrectInfo" />*/}
        {/*          <AvCheckbox customInput*/}
        {/*                      label="I agree that the employer reserves the right to require me to undergo a medical*/}
        {/*                      examination to assess my suitability to work" value="declaration2Medical" />*/}
        {/*        </AvCheckboxGroup>*/}
        {/*      </div>*/}
        {/*    </Grid>*/}
        {/*  </Grid>*/}
        {/*</div>*/}

        {/*Declaration Signature and Date*/}
        <div className="form-row">
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <div>
                <div className="form-group">
                  <label htmlFor="initials">Initials (Signature)</label>
                  <AvField
                    name="initials"
                    id="initials"
                    type="text"
                    errorMessage="Invalid Initials"
                    validate={{
                      required: { value: true },
                      minLength: { value: 2 },
                      maxLength: { value: 25 },
                    }}
                    className="custom-input"
                  />
                </div>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div>
                <div className="form-group">
                  <label htmlFor="confirmDate1">Date</label>
                  <AvField
                    name="confirmDate1"
                    id="confirmDate1"
                    type="text"
                    errorMessage="Invalid date"
                    validate={{
                      required: { value: true },
                      minLength: { value: 3 },
                      maxLength: { value: 16 },
                    }}
                    className="custom-input"
                  />
                </div>
              </div>
            </Grid>
          </Grid>
        </div>

        {/*Submit Button*/}
        <div style={{ padding: "20px" }}>
          <Grid container>
            <Grid item xs={6}>
              <Button onClick={() => handleBack()}
              >
                Back
              </Button>
            </Grid>
            <Grid item xs={6} style={{ textAlign: "right"}}>
              <FormGroup>
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  className="mui-buttons">
                  Submit</Button>
              </FormGroup>
            </Grid>
          </Grid>
        </div>

      </AvForm>

    </div>
  )
}