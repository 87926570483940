import React from 'react';
import ReferralPageBlueprint from './../Blueprints/ReferralPageBlueprint';
import CoverImage from './../../assets/single-elements/cover-image-v3.png'
import NavElements from './NavElements';
import { Button } from "reactstrap";
import {Helmet} from "react-helmet";



class HowPartnerProgramme extends React.Component {
  constructor(props) {
    super(props);
    this.supporterGuideRef = React.createRef()
    this.state = {
        isPaneOpen: false,
        isPaneOpenLeft: false,
    };

    this.onOpenSidePane = this.onOpenSidePane.bind(this)
    this.goToSignup = this.goToSignup.bind(this)
    this.goToMyAccount = this.goToSignup.bind(this)

  }

  componentDidMount() {
    if (this.supporterGuideRef && this.supporterGuideRef.current) {
      this.supporterGuideRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }

  onOpenSidePane = (e, ele) => {
    const { history } = this.props;
    if (ele === 'About') {
      history.push('/supporter-programme/home')
    }
    if (ele === 'How') {
      history.push('/supporter-programme/how')
    }
    if (ele === 'Compensation') {
      history.push('/supporter-programme/compensation')
    }
    if (ele === 'Tools') {
      history.push('/supporter-programme/tools')
    }
  }

  goToSignup = () => {
    const { history } = this.props;
    history.push('/login')
  }

  goToMyAccount = () => {
    const { history } = this.props;
    history.push('/profile')
  }

  render() {
    const title = "Ex UK Online Supporters Referral Programme"
    const bImage = CoverImage
    const subHeading = "How To"
    const pubDate = "09 Jan - 3 min read"
    return (
      <div>
        <Helmet>
          <title>{ "Ex UK Online Supporter - Affiliate Programme - How" }</title>
          <meta name="description" content="Guide: How to Join The Ex UK Online Supporter programme" />
        </Helmet>
        <section className="gen-section  filter_space">
        <div className="container mobile_padding">
          <div className="row">
            <div className="col-lg-12 col-sm-12">
              <div className="static-info-page static-info-padding" style={{paddingBottom: '60px'}}>
                <div className="" style={{ textAlign: 'center' }}>
                  <ReferralPageBlueprint
                    title={title}
                    bImage={bImage}
                    subHeading={subHeading}
                    publishingDate={pubDate}
                  >
                  </ReferralPageBlueprint>
                  <div ref={this.supporterGuideRef} className="container">
                    <NavElements
                      currentPage={"How"} onOpenSidePane={this.onOpenSidePane}
                    />
                    <div className="col-lg-3 col-lg-3 col-sm-6 col-xs-6 ">
                    </div>
                  </div>
                  <div className="supporter-program-text">
                    <div style={{ fontSize: "20px", fontWeight: 600, paddingTop: '15px', color: '#980000' }}>
                      Step 1
                    </div>
                    <div style={{ fontSize: "20px", fontWeight: 600, paddingTop: '15px' }}>
                      How to sign up as an Ex UK supporter
                    </div>
                    <div>
                      If you do have an Ex UK Online account, follow the steps below to create an account.
                      If you're an existing user but have not subscribed to the programme, skip to Step 2
                    </div>
                    <div className="col-lg-12">
                    <div className="row mt-4 bottom-out-space">
                      <div className="col-lg-12 help-cards">
                        <div className="inner-how-to-cards">
                          <div className="b-column">
                          <h6 style={{color: '#980000', paddingBottom: '5px', paddingTop: '10px'}}
                              className="info-graphics-heading"> CLICK TO SIGN UP </h6>
                          <div className="row">
                            <div className="col-12" style={{paddingBottom: '40px'}}>
                              <Button size={"sm"} onClick={() => this.goToSignup()} className="download-button">
                                Sign up
                              </Button>
                              <div style={{paddingTop: '20px'}}>
                                <i className="down"></i>
                              </div>
                            </div>
                            <div className="col-12" style={{paddingBottom: '40px', color: '#424242'}}>
                               <h6 style={{color: '#980000', paddingBottom: '2px'}}
                                   className="info-graphics-heading">ENTER YOUR DETAILS</h6>
                               <div>
                                 You will be redirected to registration form
                               </div>
                               <div>
                                 Please enter your email address and password,
                                 select your country and click "SIGN UP" button
                                 <img
                                   style={{height: '400px', paddingTop: '10px'}}
                                   src={require("../../assets/single-elements/sign-up-update.png")}
                                   className="mx-auto car-img img-responsive card-img-top"
                                   alt="how to buy"
                                 />
                               </div>
                              <div style={{paddingTop: '20px'}}>
                                <i className="down"></i>
                              </div>
                            </div>
                            <div className="col-12" style={{paddingBottom: '20px'}}>
                              <h6 style={{color: '#980000',  paddingBottom: '2px'}} className="info-graphics-heading">CONFIRM EMAIL</h6>
                              <div>
                                 You will receive an email with confirmation link. Click it to verify your account
                              </div>
                              <div style={{marginTop:"16px"}}>
                                <i className="far fa-envelope" style={{fontSize:"65px"}}></i>
                              </div>
                            </div>
                            <div className="col-12" style={{paddingBottom: '20px'}}>
                              <h6 style={{color: '#980000'}} className="info-graphics-heading">ACCOUNT IS ACTIVE</h6>
                            </div>
                          </div>
                        </div>
                        </div>
                      </div>
                      <div style={{ paddingBottom: '15px', margin: 'auto' }}>
                        <div style={{ fontSize: "20px", fontWeight: 600, paddingTop: '15px',  color: '#980000'}}>
                          Step 2
                        </div>
                        <div style={{ fontSize: "20px", fontWeight: 600, paddingTop: '15px' }}>
                        How to Join Supporters Programme
                        </div>
                        Be one of our Ex UK Online Supporters and build your own successful business.
                      </div>
                      <div className="col-lg-12 help-cards">
                        <div className="inner-how-to-cards">
                          <div className="b-column">
                          <h6 style={{color: '#980000', paddingBottom: '5px', paddingTop: '10px' }} className="info-graphics-heading"> NAVIGATE TO ACCOUNT </h6>
                          <div className="row">
                            <div className="col-12" style={{paddingBottom: '40px'}}>
                              <Button onClick={()=> this.goToMyAccount()} size={"sm"} className="download-button">My Account</Button>
                              <div style={{paddingTop: '20px'}}>
                                <i className="down"></i>
                              </div>
                            </div>
                            <div className="col-12" style={{paddingBottom: '40px', color: '#424242'}}>
                               <h6 style={{color: '#980000',  paddingBottom: '2px'}}
                                   className="info-graphics-heading">CLICK JOIN SUPPORT PROGRAM</h6>
                               <div>
                                 You will be redirected to my account page
                               </div>
                               <div>
                                 click "JOIN SUPPORT PROGRAM" button and enter your details
                                 <img
                                   style={{height: '400px', paddingTop: '10px'}}
                                   src={require("../../assets/single-elements/join-support-program-update-v3.png")}
                                   className="mx-auto car-img img-responsive card-img-top"
                                   alt="how to buy"
                                 />
                               </div>
                              <div style={{paddingTop: '5px'}}>
                                <i className="down"></i>
                              </div>
                            </div>
                            <div className="col-12" style={{paddingBottom: '20px'}}>
                              <h6 style={{color: '#980000', paddingBottom: '0px'}} className="info-graphics-heading">CONGRATULATIONS!</h6>
                              <div>
                                 You are now an Ex UK Online Supporter
                              </div>
                              <div>
                                 <img
                                   style={{height: '400px', paddingTop: '10px'}}
                                   src={require("../../assets/single-elements/support-success-update-v3.png")}
                                   className="mx-auto car-img img-responsive card-img-top"
                                   alt="how to buy"
                                 />
                               </div>
                            </div>
                          </div>
                        </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      </div>
    )
  }

}

export default HowPartnerProgramme;
