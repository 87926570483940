import React, {Component} from "react";
import EarnedPositionComponent from "../../../shared/EarnedPositionComponent";
import pdf from "../../../shared/Earned-Positions-Ex-UK-Online-v2.pdf";

class HowToPromote extends Component {

  render() {
    return (
      <div style={{paddingBottom: '20px'}}>
        <div className="supporter-program-text">
          <div style={{ paddingBottom: '40px' }}>
            <div style={{ fontSize: "20px", fontWeight: 600 }}>
              How to get paid as a supporter?
            </div>
            <div className="training-headings">
              The supporter programme is based on points and commission rate.
              Points are calculated from your total sales value over time.
              You will be instantly rewarded 100 points for finishing your training!
              <br/>
              <br/>
              <span style={{ fontWeight: 800, color: '#980000', marginLeft: '10px'}}>1 Point is worth USD1</span>
              <br/>
              <span style={{ fontStyle: 'italic', marginLeft: '10px'}}>For example if you sell a car worth $5,000 you earn 5000 Points</span>
              <br/>
              <br/>
              Commission rate is based on the <span style={{ fontWeight: 800}}>earned position</span>.
              <br/>When you join the programme and complete training, your earned
              position is <span style={{ fontWeight: 800}}>"Independent Business Owner" (IBO)</span>.
              <br/>
              <br/>
              <span style={{ fontStyle: 'italic'}}>See table below for the corresponding commission rates for earned positions</span>
              <EarnedPositionComponent/>
            </div>
    </div>

          <div style={{ paddingBottom: '40px' }}>
            <div style={{ fontSize: "20px", fontWeight: 600 }}>
              Earned Positions
            </div>
            <div className="training-headings">
              To earn more, your goal should be to work your way through the earned positions.
            </div>
            <p>
              <a className="link-text" href={pdf}>Click here to learn more about earned positions</a>
            </p>
          </div>
        </div>
      </div>
    )
  }
}

export default HowToPromote;