import React from 'react';
import {
    Button, Input, InputGroup,
    InputGroupAddon

} from 'reactstrap';
// import Garantee from './Garantee'
function Footer() {
    return (
        <div className="footer-spacer">
             {/*<Garantee/>*/}
        <div className="footer">
            {/*<div className="upper-footer-section">*/}
            {/*    <div className="container">*/}
            {/*        <div className="row">*/}
            {/*            <div className="col-lg-12">*/}
            {/*                <h3>Make Money With</h3>*/}
            {/*                <Button className="btn button"> Sign up</Button>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
            <div className="middle-footer-section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="row  mt-4">
                                <div className="col-sm-6 col-md-6 col-lg-6">
                                    <span className="footer-link-text">CONTACT INFORMATION</span>
                                    <ul className="list-unstyled info" style={{ lineHeight: '5vh' }}>
                                        <li className="number"><div className="footer-link-text-div">+44 7943 273454</div><div className="footer-div">(10am to 6pm)</div></li>
                                        <li className="customer-care"><div className="footer-link-text-div">Customer service</div>Live Chat</li>
                                        <li className="support-mail"><div className="footer-link-text-div">support@exukonline.com</div><div className="footer-div">Response within 6hrs</div></li>
                                    </ul>
                                </div>
                                <div className="col-sm-6 footer-div">
                                    <ul className="list-unstyled">
                                        <span className="footer-link-text">OVERVIEW</span>
                                        <li className="footer-link-text-div">Privacy Policy</li>
                                        <li className="footer-link-text-div">Terms & Conditions</li>
                                        <li className="footer-link-text-div">Advertise with us</li>
                                        <li className="footer-link-text-div">
                                            <a style={{color: '#007afc'}}
                                               href="https://exukonline.com/job-application" target="_blank">
                                                Apply for job
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                {/*<div className="col-lg-4">*/}
                                {/*    <ul className="list-unstyled">*/}
                                {/*        <span className="footer-link-text">CARS</span>*/}
                                {/*        <li>BMW</li>*/}
                                {/*        <li>AUDI</li>*/}
                                {/*        <li>JAGUAR</li>*/}
                                {/*        <li>MERCEDES BENZ</li>*/}
                                {/*        <li>RANGE ROVER</li>*/}
                                {/*        <li>LEXUS</li>*/}
                                {/*    </ul>*/}
                                {/*</div>*/}
                                {/*<div className="col-lg-2 offset-lg-1">*/}
                                {/*    <ul className="list-unstyled">*/}
                                {/*        <span>CARS</span>*/}
                                {/*        <li>BMW</li>*/}
                                {/*        <li>AUDI</li>*/}
                                {/*        <li>JAGUAR</li>*/}
                                {/*        <li>MERCEDES</li>*/}
                                {/*        <li>PEUGEOT</li>*/}
                                {/*        <li>FORD</li>*/}
                                {/*    </ul>*/}
                                {/*</div>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="third-footer-section" style={{display: "None"}}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <h6>Join Our Newsletter Now</h6>
                            <p>Get E-mail updates about out latest shop and special offers.</p>
                        </div>
                        <div className="col-lg-4">
                            {/* <InputGroup>
                                <Input type="text" className="footer-input" placeholder="Enter your mail" />
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>To the Left!</InputGroupText>
                                </InputGroupAddon>
                            </InputGroup> */}

                            <InputGroup>
                                <Input type="text" className="footer-input" placeholder="Enter your mail" />
                                <InputGroupAddon addonType="prepend"><Button id="btn-addon">SUBSCRIBE</Button></InputGroupAddon>
                            </InputGroup>


                        </div>
                    </div>
                </div>
            </div>
            <div className="fourth-footer-section" style={{display: "None"}}>
                {/*<div className="container">*/}
                {/*    <div className="row">*/}
                {/*        <div className="col-lg-9">*/}
                {/*            <p>Copyright @ 2019 exUKonline - All Rights Reserved.</p>*/}
                {/*        </div>*/}
                {/*        <div className="col-lg-3">*/}
                {/*            <ul className="list-unstyled payments">*/}
                {/*                <li>*/}
                {/*                        <img src={require('../assets/single-elements/commercial_cover_credit_card_skrill_moneybookers-512.png')} alt="payment" className="payment-cards" />*/}
                {/*                        </li>*/}
                {/*                <li>*/}
                {/*                        <img src={require('../assets/single-elements/bitcoin-png-image-68816.png')} className="payment-cards" alt="payment"/>*/}
                {/*                        </li>*/}
                {/*                        <li>*/}
                {/*                        <img src={require('../assets/single-elements/american_express-512.png')} className="payment-cards" alt="payment" style={{ marginTop: '3px'}}/>*/}
                {/*                        </li>*/}
                {/*                        <li>*/}
                {/*                        <img src={require('../assets/single-elements/paypal-png-image-37936.png')} alt="payment" className="payment-cards" />*/}
                {/*                        </li>*/}
                {/*                        <li>*/}
                {/*                        <img src={require('../assets/single-elements/master.jpg')} alt="payment" className="payment-cards"/>*/}
                {/*                        </li>*/}
                {/*                        <li>*/}
                {/*                        <img src={require('../assets/single-elements/download.jpg')} alt="payment" className="payment-cards" />*/}
                {/*                        </li>*/}
                {/*            </ul>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
        </div>
    </div>
    )
}

export default Footer;