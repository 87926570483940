import * as React from 'react'
import { AvForm, AvField, } from 'availity-reactstrap-validation';
import { Button, FormGroup } from 'reactstrap';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {useEffect} from "react";

export default function QuestionTwo({handleQ, sectionTwoValues, handleBack}) {

  const handleSubmit = (event, errors, values) => {

    const criticalErrors = ['illness', 'disabilityAdjustments', 'disability', 'employer1',
      'empTitleDesc1', 'empEndReason1'];

    const includesErrors = errors.some((element) => criticalErrors.includes(element));
    if (includesErrors) {
      return;
    }
    handleQ(errors, values, 2)
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <div>
      <AvForm onSubmit={handleSubmit} model={sectionTwoValues} className="my-custom-form">

        {/*Education Section*/}
        <div style={{ padding: "16px 0px 0px 0px"}}>
          <div style={{ padding: "2px 0px 20px 0px"}}>
            <Typography>
              Present or former employer
            </Typography>
          </div>
        </div>

        {/*Education header 1*/}
        <div className="form-row">
          <Grid container spacing={2}>
            <Grid item={12} style={{width: "100%"}}>
              <div className="form-group">
                <label htmlFor="employer1">Name of Employer:</label>
                <AvField
                  name="employer1"
                  id="employer1"
                  type="text"
                  errorMessage="Invalid"
                  validate={{
                    required: { value: true },
                    minLength: { value: 3 },
                    maxLength: { value: 72 },
                  }}
                  className="custom-input"
                />
              </div>
            </Grid>
          </Grid>
        </div>

        {/*Education period 1*/}
        <div className="form-row">
          <Grid container spacing={2}>
            <Grid item xs={6} >
              <div className="form-group">
                <label htmlFor="empDateFrom1">Date From:</label>
                <AvField
                  id="empDateFrom1"
                  name="empDateFrom1"
                  className="form-control"
                  required
                ></AvField>
              </div>
            </Grid>
            <Grid item xs={6} >
              <div className="form-group">
                <label htmlFor="dateTo1">Date To:</label>
                <AvField
                  id="empDateTo1"
                  name="empDateTo1"
                  className="form-control"
                  required
                ></AvField>
              </div>
            </Grid>
          </Grid>
        </div>

        {/*Emp detail 1*/}
        <div className="form-row">
          <Grid container spacing={2} >
            <Grid item xs={6} >
              <div className="form-group">
                <label htmlFor="empTitleDesc1">Job title and description of duties:</label>
                <AvField
                  style={{width: "100%"}}
                  type="textarea"
                  name="empTitleDesc1"
                  rows="4" // Set the number of rows you want to display
                  placeholder="Text here..."
                  required
                />
              </div>
            </Grid>
            <Grid item xs={6} >
              <div className="form-group">
                <label htmlFor="empEndReason1">Reason for leaving</label>
                <AvField
                  style={{width: "100%"}}
                  type="textarea"
                  name="empEndReason1"
                  rows="4" // Set the number of rows you want to display
                  placeholder="Text here..."
                  required
                />
              </div>
            </Grid>
          </Grid>
        </div>

        <hr style={{border: "2px solid #000", margin: "24px 0px 24px 0px"}}/>

        {/*Emp header 2*/}
        <div className="form-row">
          <Grid container spacing={2}>
            <Grid item={12} style={{width: "100%"}}>
              <div className="form-group">
                <label htmlFor="employer2">Name of Employer:</label>
                <AvField
                  name="employer2"
                  id="employer2"
                  type="text"
                  errorMessage="Invalid"
                  validate={{
                    required: { value: true },
                    minLength: { value: 3 },
                    maxLength: { value: 72 },
                  }}
                  className="custom-input"
                />
              </div>
            </Grid>
          </Grid>
        </div>

        {/*Emp period 2*/}
        <div className="form-row">
          <Grid container spacing={2}>
            <Grid item xs={6} >
              <div className="form-group">
                <label htmlFor="empDateFrom2">Date From:</label>
                <AvField
                  id="empDateFrom2"
                  name="empDateFrom2"
                  className="form-control"
                  required
                ></AvField>
              </div>
            </Grid>
            <Grid item xs={6} >
              <div className="form-group">
                <label htmlFor="dateTo1">Date To:</label>
                <AvField
                  id="empDateTo2"
                  name="empDateTo2"
                  className="form-control"
                  required
                ></AvField>
              </div>
            </Grid>
          </Grid>
        </div>

        {/*Emp detail 2*/}
        <div className="form-row">
          <Grid container spacing={2} >
            <Grid item xs={6} >
              <div className="form-group">
                <label htmlFor="empTitleDesc2">Job title and description of duties:</label>
                <AvField
                  style={{width: "100%"}}
                  type="textarea"
                  name="empTitleDesc2"
                  rows="4" // Set the number of rows you want to display
                  placeholder="Text here..."
                  required
                />
              </div>
            </Grid>
            <Grid item xs={6} >
              <div className="form-group">
                <label htmlFor="empEndReason2">Reason for leaving</label>
                <AvField
                  style={{width: "100%"}}
                  type="textarea"
                  name="empEndReason2"
                  rows="4" // Set the number of rows you want to display
                  placeholder="Text here..."
                  required
                />
              </div>
            </Grid>
          </Grid>
        </div>

        <hr style={{border: "2px solid #000", margin: "24px 0px 24px 0px"}}/>

        {/*Education header 3*/}
        <div className="form-row">
          <Grid container spacing={2}>
            <Grid item={12} style={{width: "100%"}}>
              <div className="form-group">
                <label htmlFor="employer3">Name of Employer:</label>
                <AvField
                  name="employer3"
                  id="employer3"
                  type="text"
                  errorMessage="Invalid"
                  validate={{
                    required: { value: true },
                    minLength: { value: 3 },
                    maxLength: { value: 72 },
                  }}
                  className="custom-input"
                />
              </div>
            </Grid>
          </Grid>
        </div>

        {/*Education period 3*/}
        <div className="form-row">
          <Grid container spacing={2}>
            <Grid item xs={6} >
              <div className="form-group">
                <label htmlFor="empDateFrom3">Date From:</label>
                <AvField
                  id="empDateFrom3"
                  name="empDateFrom3"
                  className="form-control" // You can specify the number of rows you want
                  required
                ></AvField>
              </div>
            </Grid>
            <Grid item xs={6} >
              <div className="form-group">
                <label htmlFor="dateTo1">Date To:</label>
                <AvField
                  id="empDateTo3"
                  name="empDateTo3"
                  className="form-control" // You can specify the number of rows you want
                  required
                ></AvField>
              </div>
            </Grid>
          </Grid>
        </div>

        {/*Emp detail 3*/}
        <div className="form-row">
          <Grid container spacing={2} >
            <Grid item xs={6} >
              <div className="form-group">
                <label htmlFor="empTitleDesc3">Job title and description of duties:</label>
                <AvField
                  style={{width: "100%"}}
                  type="textarea"
                  name="empTitleDesc3"
                  rows="4" // Set the number of rows you want to display
                  placeholder="Text here..."
                  required
                />
              </div>
            </Grid>
            <Grid item xs={6} >
              <div className="form-group">
                <label htmlFor="empEndReason3">Reason for leaving</label>
                <AvField
                  style={{width: "100%"}}
                  type="empEndReason3"
                  name="anyOtherTraining"
                  rows="4" // Set the number of rows you want to display
                  placeholder="Text here..."
                  required
                />
              </div>
            </Grid>
          </Grid>
        </div>

        <hr style={{border: "2px solid #000", margin: "24px 0px 24px 0px"}}/>


        {/*Gaps in employement*/}
        <div className="form-row">
          <Grid container spacing={2} >
            <Grid item xs={12} >
              <div className="form-group">
                <label htmlFor="gapsEmployment">
                  If there are gaps in employment, please tell us why e.g. unemployment, bringing up family etc
                </label>
                <AvField
                  style={{width: "100%"}}
                  type="textarea"
                  name="gapsEmployment"
                  rows="4" // Set the number of rows you want to display
                  placeholder="Text here..."
                  required
                />
              </div>
            </Grid>
          </Grid>
        </div>

        {/*Additional Information Header*/}
        <div style={{ padding: "16px 0px 0px 0px"}}>
          <div>
            <Typography>
              <h3>Addtional Information</h3>
            </Typography>
          </div>
        </div>

        {/*Physical Disability*/}
        <div className="form-row">
          <Grid container spacing={2}>
            <Grid item={12} style={{width: "100%"}}>
              <div className="form-group">
                <label htmlFor="disability">
                  Do you have any mental or physical disability or illness (currently or recurring) which is relevant
                  to the post for which you are applying?, if so please give details:
                </label>
                <AvField
                  name="disability"
                  id="disability"
                  type="text"
                  errorMessage="Invalid"
                  validate={{
                    required: { value: true },
                    minLength: { value: 2 },
                    maxLength: { value: 200 },
                  }}
                  className="custom-input"
                />
              </div>
            </Grid>
          </Grid>
        </div>

        {/*Physical Disability*/}
        <div className="form-row">
          <Grid container spacing={2}>
            <Grid item={12} style={{width: "100%"}}>
              <div className="form-group">
                <label htmlFor="disabilityAdjustments">
                  What adjustments (if any) need to be made to the working environment to accommodate your disability?
                </label>
                <AvField
                  name="disabilityAdjustments"
                  id="disabilityAdjustments"
                  type="text"
                  errorMessage="Invalid"
                  validate={{
                    required: { value: true },
                    minLength: { value: 2 },
                    maxLength: { value: 200 },
                  }}
                  className="custom-input"
                />
              </div>
            </Grid>
          </Grid>
        </div>

        {/*Illness accidents injuries*/}
        <div className="form-row">
          <Grid container spacing={2}>
            <Grid item={12} style={{width: "100%"}}>
              <div className="form-group">
                <label htmlFor="illness">
                  Please give details of any illness/accidents/injuries in the last 2 years?
                </label>
                <AvField
                  name="illness"
                  id="illness"
                  type="text"
                  errorMessage="Invalid"
                  validate={{
                    required: { value: true },
                    minLength: { value: 2 },
                    maxLength: { value: 200 },
                  }}
                  className="custom-input"
                />
              </div>
            </Grid>
          </Grid>
        </div>

        {/*Next Button*/}
        <div style={{ padding: "20px" }}>
          <Grid container>
            <Grid item xs={6}>
              <Button onClick={() => handleBack()}
              >
                Back
              </Button>
            </Grid>
            <Grid item xs={6} style={{ textAlign: "right"}}>
              <FormGroup>
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  className="mui-buttons">
                  Continue</Button>
              </FormGroup>
            </Grid>
          </Grid>
        </div>

      </AvForm>

    </div>
  )
}