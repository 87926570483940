import React from 'react';
import {Button, Card, CardBody} from 'reactstrap';


const ConfirmOrder  = ({onMyAccount, onContinueShopping}) => {
  return (
    <div className="bottom-out-space-padding">
      <div className="">
        <Card className="bg-light">
          <CardBody style={{color:"#393939"}} className="text-center">
            <i className="fa fa-check-circle" style={{fontSize:"40px", color:"#009800"}}></i>
            <p></p>
            <div className="basket-page-information" style={{fontSize: '14px'}}> CONGRATULATIONS! <p>
              YOUR ORDER IS ACCEPTED </p>
            </div>
            <i className="far fa-envelope" style={{fontSize:"40px"}}></i>
            <p></p>
            <div className="basket-page-information" style={{fontSize: '14px'}}>
              You will receive an email shortly with the payment instructions. <br/>
              <p>
              If you have more questions, please reply to the email so we can
                assist you.</p>
            </div>
          </CardBody>
        </Card>
      </div>
        <div className="row mbtn">
          <div className="col-sm-6 text-center">
            <Button className="delete-basket-button" size="sm" onClick={onMyAccount.bind(this)}>My Account</Button>
          </div>
          <div className="col-sm-6 text-center">
            <Button className="delete-basket-button" size="sm" onClick={onContinueShopping.bind(this)}>Continue Shopping</Button>
          </div>
        </div>
    </div>
  );
}


export default ConfirmOrder;