import * as React from 'react'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import {useEffect} from "react";

export default function SubmitInquiry({handleSubmit}) {

  useEffect(() => {
    window.scrollTo(0, 0)
    handleSubmit()
  },[]);

  return (
    <div style={{ textAlign: "center" }}>
      <div>
        <CheckCircleRoundedIcon style={{ fontSize: 60, color: "green"}} className="material-icons-done"/>
      </div>
      <div style={{ padding: "8px 0px 20px 0px"}}>
        <span style={{ fontWeight: 600}}>Form submitted!</span>
      </div>
      <div>
        <p>
          Your inquiry has been submitted successfully.
        </p>
        <p>
          You will receive an email or call with follow on instructions.
        </p>
      </div>

    </div>
  )
}