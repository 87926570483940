import React, { Component } from "react";
import { withRouter} from "react-router-dom";
import "react-alice-carousel/lib/alice-carousel.css"
import ConfirmResendActivation from './ConfirmResendActivation'
import { withCookies } from "react-cookie";
import { compose } from "redux";
import { Form, FormGroup, Input, Label } from "reactstrap";


class ResendActivationComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email:"",
      error: false,
      message:"",
      activeReset: false,
      responseCode: 0
    };

    this.handleResendActivationLink = this.handleResendActivationLink.bind(this);
    this.onChange = this.onChange.bind(this)
  }

  componentWillReceiveProps(nextProps) {
  }

  componentDidUpdate(prevProps) {

    let { resendActivationLinkSuccess, resendActivationLinkError } = this.props
    if (resendActivationLinkSuccess && prevProps.resendActivationLinkSuccess !== resendActivationLinkSuccess ) {
      this.setState({activeReset: true})
      if (resendActivationLinkSuccess.msg === "Account is activated already") {
        this.setState({responseCode: 2})
      }
      if (resendActivationLinkSuccess.msg === "User does not exists") {
        this.setState({responseCode: 3})
      }
      if (resendActivationLinkSuccess.msg === "Activation Link re-sent") {
        this.setState({responseCode: 4})
      }
    }
    if (resendActivationLinkError && prevProps.resendActivationLinkError !== resendActivationLinkError ) {
       this.setState({responseCode: 5, activeReset: 1})
    }
  }

  handleResendActivationLink(event) {
    const { email } = this.state;
    event.preventDefault();

    this.props.resendActivationLink && this.props.resendActivationLink({
      email: email,
    })
  }

  onChange(event){
    const { name, value} = event.target;
    this.setState({ [`${name}`]: value });
  };


  render() {

    const { email, activeReset, responseCode } = this.state

    return (
      <div>
        <header className="headerbg d-flex">
          <div className="container my-auto">
            {!activeReset &&
            <div className="row">
              <div className="offset-1 col-10 offset-lg-4 col-lg-4">
                <div id="search-form-div" className="container">
                  <div className="row tab-content" style={{paddingTop: '20px'}}>
                    <div className="col-12 my-4">
                      <Form onSubmit={this.handleResendActivationLink}>
                        <FormGroup >
                          <Label for="email">Email address</Label>
                          <Input
                              onChange={this.onChange}
                              type="email"
                              name="email"
                              id="activation-resend"
                              value={email}
                          />
                        </FormGroup>

                        <FormGroup>
                            <Input type="submit" name="signin" id="signin" style={{color: 'white'}} className="btn btn-primary" value="Resend activation" />
                        </FormGroup>
                      </Form>
                      </div>
                  </div>
                </div>
              </div>
            </div>
            }
            {activeReset &&
            <ConfirmResendActivation responseCode={responseCode} /> }
          </div>
          </header>

      </div>
    );
    }
}

export default compose(
    withCookies,
    withRouter
)(ResendActivationComponent);
