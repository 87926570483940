import React from 'react'
import {Link} from 'react-router-dom';

const Activate = (props) => {
  return (
    <div className="row">
        <div className="offset-1 col-10 offset-lg-1 col-lg-10">
            <div className="container jumbotron text-center">
                <h6 style={{color:"#6fa8dd", marginBottom:"20px"}}>Account Created</h6>
                <h7 style={{color:"#6fa8dd"}}>Verify your email address </h7>
                <h7 style={{color:"#6fa8dd"}}>to access all of exUKonline.com features</h7>

                <div style={{marginTop:"16px"}}>
                    <i className="far fa-envelope" style={{fontSize:"65px"}}></i>
                </div>  

                <p style={{marginTop:"15px", fontSize:"13px"}} className="page-information">Verification email was sent to <br/>
                <p style={{textDecoration:"underline" ,color:"#6fa8dd" , fontSize:"13x"}}>
                    {props.email}
                </p><br/>
                Open the email and click the activation link. It may take up to 15 minutes to receive the email
                </p>
                <p style={{fontWeight: "500", textAlign: "center", fontSize:"13px"}}>
                  Tip: Check your spam folder in case the email was incorrectly identified.
                  <p> Didn't receive the email? </p>
                  <a href="/resend-activation-link" className="a-links"> Resend Email </a>
                </p>


                <div className="col text-center row justify-content-center">
                  <Link
                    to="/"
                    className="btn btn-primary linkInquiryBtn">
                    Continue
                  </Link>
                </div>

                
            </div>
        </div>
   </div>
  )
}

export default Activate
