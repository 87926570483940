import React, {Component} from 'react';
import ReferralPageBlueprint from './../Blueprints/ReferralPageBlueprint';
import CoverImage from './../../assets/single-elements/cover-image-v3.png'
import NavElements from "./NavElements";
import {Helmet} from "react-helmet";
import {Button} from "reactstrap";


class ToolsPartnerProgramme extends Component {
  constructor(props) {
    super(props);
    this.supporterGuideRef = React.createRef()
    this.state = {
        isPaneOpen: false,
        isPaneOpenLeft: false,
    };

    this.onOpenSidePane = this.onOpenSidePane.bind(this)

  }

   componentDidMount() {
    if (this.supporterGuideRef && this.supporterGuideRef.current) {
      this.supporterGuideRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }

  onOpenSidePane = (e, ele) => {
    const { history } = this.props;
    if (ele === 'About') {
      history.push('/supporter-programme/home')
    }
    if (ele === 'How') {
      history.push('/supporter-programme/how')
    }
    if (ele === 'Compensation') {
      history.push('/supporter-programme/compensation')
    }
    if (ele === 'Tools') {
      history.push('/supporter-programme/tools')
    }
  }

  goToPromoTools = () => {
    const { history } = this.props;
    history.push('/advertising-tools')
  }

  render() {
    const title = "Ex UK Online Supporters Referral Programme"
    const bImage = CoverImage
    const subHeading = "Referral Programme System Tools"
    const pubDate = "09 Jan - 3 min read"

    return (
      <div>
        <Helmet>
          <title>{ "Ex UK Online Supporter - Affiliate Programme - Tools" }</title>
          <meta name="description" content="Tool and Resources, download business cards, flyer and banner designs" />
        </Helmet>
        <section className="gen-section  filter_space">
        <div className="container mobile_padding">
          <div className="row">
            <div className="col-lg-12 col-sm-12">
              <div className="static-info-page static-info-padding" style={{paddingBottom: '60px'}}>
                <div className="" style={{ textAlign: 'center' }}>
                  <ReferralPageBlueprint
                    title={title}
                    bImage={bImage}
                    subHeading={subHeading}
                    publishingDate={pubDate}
                  >
                  </ReferralPageBlueprint>
                  <div ref={this.supporterGuideRef} className="container">
                    <NavElements
                      currentPage={"Tools"} onOpenSidePane={this.onOpenSidePane}
                    />
                    <div className="col-lg-3 col-lg-3 col-sm-6 col-xs-6 ">
                    </div>
                  </div>
                  <div className="supporter-program-text">
                    <div style={{ fontSize: "20px", fontWeight: 600, paddingTop: '15px' }}>
                      Promotion Tools
                    </div>
                    <div>
                      Get access to Free Marketing Material and start creating your very own successful
                      business in a few simple steps. Marketing material is only available to users who are subscribed
                      to the programme.
                    </div>
                    <br/>
                    <div style={{ paddingBottom: '10px', color: '#808080' }}>
                      How to access FREE Marketing Material
                    </div>
                    <div className="inner-how-to-cards">
                      <h6 style={{color: '#980000', paddingTop: '10px', paddingBottom: '0px', marginBottom: '0px'}}
                              className="info-graphics-heading"> LOGIN TO YOUR ACCOUNT </h6>
                       <div style={{paddingTop: '20px'}}>
                          <i className="down"></i>
                        </div>
                      <h6 style={{color: '#980000', paddingBottom: '5px', paddingTop: '40px'}}
                              className="info-graphics-heading"> CLICK FOR PROMOTION TOOLS PAGE </h6>
                      <div className="row">
                      <div className="col-12" style={{paddingBottom: '40px'}}>
                        <Button size={"sm"} onClick={() => this.goToPromoTools()} className="download-button">
                          Promo Tools
                        </Button>
                        <div style={{paddingTop: '20px'}}>
                          <i className="down"></i>
                        </div>
                      </div>
                      <div className="col-12" style={{paddingBottom: '40px', color: '#424242'}}>
                         <h6 style={{color: '#980000', paddingBottom: '2px'}}
                             className="info-graphics-heading">ENTER YOUR DETAILS</h6>
                         <div>
                           You will be redirected to promotion tool page
                         </div>
                         <div>
                           Select your preferred material and click download.
                           <div>
                             When you download the material, your name and
                              Ex UK Promo code will be automatically added
                           </div>
                           <img
                             style={{height: '400px', paddingTop: '10px'}}
                             src={require("../../assets/single-elements/promo-how-to-v5.png")}
                             className="mx-auto car-img img-responsive card-img-top"
                             alt="how to buy"
                           />
                         </div>
                        <div style={{paddingTop: '20px'}}>
                          <i className="down"></i>
                        </div>
                      </div>
                      <div className="col-12" style={{paddingBottom: '20px'}}>
                        <h6 style={{color: '#980000',  paddingBottom: '2px'}} className="info-graphics-heading">
                          MATERIALS AVAILABLE
                        </h6>
                        <div>
                           Business Cards, A5 Flyer, Vinyl Banner
                        </div>
                        <div style={{marginTop:"16px"}}>
                        </div>
                      </div>
                      <div className="col-12" style={{paddingBottom: '20px'}}>
                      </div>
                    </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      </div>
    )
  }

}

export default ToolsPartnerProgramme;
