import React, { Component } from 'react';
import { Form, FormGroup, Input, Alert } from 'reactstrap';
import 'react-input-range/lib/css/index.css';
import { Link } from 'react-router-dom';
import { veh_data } from '../shared/vehicle_make_and_models';
import './ImgAndForm.css';

class ImgAndForm extends Component {
  constructor(props) {
    super(props);
    this.searchRef = React.createRef();

    this.handleSearch = this.handleSearch.bind(this);
    this.keyToOption = this.keyToOption.bind(this);
    this.toggle = this.toggle.bind(this);
    this.handleMakeChange = this.handleMakeChange.bind(this);
    this.handleModelChange = this.handleModelChange.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.state = {
      value4: {
        min: 100,
        max: 5000
      },
      fromPrice: 0,
      toPrice: 75000,
      makeSelected: null,
      modelSelected: 'any',
      typeSelected: 'cars',
      message: '',
      carPrices: [
        0,
        100,
        500,
        1000,
        1500,
        2000,
        2500,
        3000,
        3500,
        4000,
        4500,
        5000,
        5500,
        6000,
        6500,
        7000,
        7500,
        8000,
        8500,
        9000,
        9500,
        10000,
        11000,
        12000,
        13000,
        14000,
        15000,
        16000,
        17000,
        18000,
        19000,
        20000,
        22500,
        25000,
        27500,
        30000,
        35000,
        40000,
        45000,
        50000,
        55000,
        60000,
        65000,
        70000,
        75000,
        150000
      ]
    };
  }

  handleChange = e => {
    this.setState({ value: e.target.value });
  };

  toggle(typeSelected) {
    if (this.state.typeSelected !== typeSelected) {
      this.setState({ typeSelected });
    }
  }

  error(message) {
    this.setState({ message });
  }

  handleChange = e => {
    this.setState({ value: e.target.value });
  };
  handleMakeChange(event) {
    this.setState({ makeSelected: event.target.value });
  }

  handleModelChange(event) {
    this.setState({ modelSelected: event.target.value });
  }

  handleFromPriceChange = event => {
    this.setState({ fromPrice: event.target.value });
  };
  handleToPriceChange = event => {
    this.setState({ toPrice: event.target.value });
  };

  async handleSearch(event) {
    event.preventDefault();
    let {
      makeSelected,
      fromPrice,
      toPrice
    } = this.state;
    if (makeSelected === null) {
      this.error('Make is missing');
      this.searchRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
      return;
    }
    const query = `partner-one-search/${makeSelected}/${fromPrice}/${toPrice}/1/b`;
    this.props.onSearch(query);
  }

  keyToOption(key) {
    return key
      .split('-')
      .map(word => word.slice(0, 1).toUpperCase() + word.slice(1))
      .join(' ');
  }

  render() {
    const vehicles = veh_data.reduce(
      (acc, veh, i) => {
        let make = Object.keys(veh)[0];
        let vehModels = veh[make];
        return {
          makes: [
            ...acc.makes,
            <option key={make + i} value={make}>
              {this.keyToOption(make)}
            </option>
          ],
          models: {
            ...acc.models,
            [make]: [
              <option key={make + i + 0} value="any">
                Any Model
              </option>,
              vehModels.map((model, i) => {
                return (
                  <option key={make + model + i} value={model}>
                    {this.keyToOption(model)}
                  </option>
                );
              })
            ]
          }
        };
      },
      { makes: [], models: [] }
    );

    const selectMinValues = this.state.carPrices
      .filter((val) => {
        return val < this.state.toPrice
      })
      .map((val, i) => {
      return (
        <option style={{ fontWeight: 700}} key={val + i} value={val}>
          {val}
        </option>
      );
    });

    const selectMaxValues = this.state.carPrices
      .filter((val) => {
        return val > this.state.fromPrice
      })
      .map((val, i) => {
      return (
        <option style={{ fontWeight: 700}} key={val + i} value={val}>
          {val}
        </option>
      );
    });


    const selectedModels =
      this.state.makeSelected && this.state.makeSelected.length ? (
        vehicles.models[this.state.makeSelected]
      ) : (
        <option selected disabled>
          {/*Model (select make first)*/}
        </option>
      );
    return (
      <div
        className="col-lg-12"
        style={{ padding: '0px' }}
      >
        <header className="headerbg d-flex">
          <div
            className="col-sm-12 col-xs-12 offset-lg-0 col-lg-4 col-md-4"
            style={{ paddingLeft: '0px', paddingRight: '0px' }}
          >
            <div className="container my-auto">
              <div className="bg-text">
                <h5 className="img-form-header" style={{fontSize: '100%', fontWeight: 700}}>Search</h5>
                <div
                  className="container"
                  style={{
                    backgroundColor: 'white',
                    padding: '5px 10px 20px 10px'
                  }}
                >
                  {/*<div className="tab">*/}
                  {/*  <button*/}
                  {/*    id="cars"*/}
                  {/*    className={*/}
                  {/*      this.state.typeSelected === 'cars' ? 'tab-active' : ''*/}
                  {/*    }*/}
                  {/*    onClick={() => {*/}
                  {/*      this.toggle('cars');*/}
                  {/*    }}*/}
                  {/*  >*/}
                  {/*    Cars*/}
                  {/*  </button>*/}
                  {/*  <button*/}
                  {/*    id="commercial"*/}
                  {/*    className={*/}
                  {/*      this.state.typeSelected === 'commercials'*/}
                  {/*        ? 'tab-active'*/}
                  {/*        : ''*/}
                  {/*    }*/}
                  {/*    onClick={() => {*/}
                  {/*      this.toggle('commercials');*/}
                  {/*    }}*/}
                  {/*  >*/}
                  {/*    Commercials*/}
                  {/*  </button>*/}
                  {/*</div>*/}
                  <Form
                    onSubmit={this.handleSearch}
                    style={{ marginTop: '5px' }}
                  >
                    <div ref={this.searchRef}>
                    <FormGroup>
                      {this.state.message && (
                        <Alert color="danger">{this.state.message}</Alert>
                      )}
                    </FormGroup>
                    </div>
                    <FormGroup>
                      <Input
                        className="search-make"
                        placeholder="Search for anything"
                        onChange={e => this.handleMakeChange(e)}
                        type="text"
                        name="q"
                        id="q"
                        autoComplete="off"
                        style={{border: '2px solid #424242'}}
                      >
                        {/*<option selected disabled>*/}
                        {/*  All Vehicles Type*/}
                        {/*</option>*/}
                        {/*{vehicles.makes}*/}
                      </Input>

                      <Input
                        style={{display: 'None'}}
                        onChange={e => this.handleModelChange(e)}
                        type="select"
                        name="m"
                        id="m"
                      >
                        {/*{selectedModels}*/}
                      </Input>
                      <div style={{color: '#424242', fontSize: '14px'}}>Price Range ($)</div>
                      <Input
                        placeholder="Select"
                        type="select"
                        name="select"
                        value={this.state.fromPrice}
                        onChange={e => this.handleFromPriceChange(e)}
                        id="exampleSelect"
                        style={{ margin: '10px 0 10px 0', border: '2px solid #424242', fontWeight: 700 }}
                      >
                        <option disabled>
                          Price From
                        </option>
                        {selectMinValues}
                        {/*{this.state.carPrices*/}
                        {/*  .slice(0, this.state.carPrices.length - 1)*/}
                        {/*  .map(value => (*/}
                        {/*    <option*/}
                        {/*      value={`${value}`}*/}
                        {/*    >{`From $${value}`}</option>*/}
                        {/*  ))}*/}
                      </Input>
                      <Input
                        // defaultValue="Price To"
                        type="select"
                        name="select"
                        value={this.state.toPrice}
                        onChange={e => this.handleToPriceChange(e)}
                        id="exampleSelect"
                        style={{border: '2px solid #424242', fontWeight: 700}}
                      >
                        <option disabled>
                          Price To
                        </option>
                        {selectMaxValues}
                        {/*{this.state.carPrices*/}
                        {/*  .slice(*/}
                        {/*    this.state.carPrices.findIndex(*/}
                        {/*      value => value === this.state.fromPrice*/}
                        {/*    ) + 1,*/}
                        {/*    this.state.carPrices.length*/}
                        {/*  )*/}
                        {/*  .map(item => (*/}
                        {/*    <option value={`${item}`}>{`To $${item}`}</option>*/}
                        {/*  ))}*/}
                      </Input>
                    </FormGroup>
                    <FormGroup style={{ marginBottom: '0.5rem', fontFamily: 'Roboto Condensed'}}>
                      <Input
                        type="submit"
                        name="search"
                        id="search"
                        className="btn btn-primary"
                        value="Search"
                        style={{ fontFamily: 'Roboto Condensed', fontSize: '14px'}}
                      />
                    </FormGroup>
                    <Link to="/" className="advance-search">
                      Advanced Search <i className="fas">&#xf105;</i>
                    </Link>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </header>
        <div className="col-lg-10" />
        <div className="container" style={{ backgroundColor: 'white' }}>
          <div className="header-bottom ">
            <div className="row" style={{boxShadow: "4px 4px 1px #EEE", lineHeight: 1.25}}>
              <div className="col-12 col-md-6 col-sm-6 col-lg-3 bottom-badges">
                <div className="vertical-line">&nbsp;</div>
                <img
                  src={require('../assets/single-elements/uk-badge.png')}
                  alt="badge"
                  className="bottom-badges"
                  style={{maxWidth: '30px'}}
                />
                <div className="banners-head">
                  <Link to="/">
                    <span className="arcolades"> UK PREMIER </span>
                    <p>Best Quality and Service</p>
                  </Link>
                </div>
              </div>
              <div className="col-12 col-md-6 col-sm-6 col-lg-3 bottom-badges">
                <div className="vertical-line">&nbsp;</div>
                <img
                  src={require('../assets/single-elements/car-solo.png')}
                  alt="badge"
                  className="bottom-badges"
                  style={{maxWidth: '40px'}}
                />
                <div className="banners-head">
                  <Link to="/">
                    <span className="arcolades"> CAR SOLD </span>
                    <p>Every 4 minutes</p>
                  </Link>
                </div>
              </div>
              <div className="col-12 col-md-6 col-sm-6 col-lg-3 bottom-badges">
                <div className="vertical-line">&nbsp;</div>
                <img
                  src={require('../assets/single-elements/label.png')}
                  alt="badge"
                  className="bottom-badges"
                  style={{maxWidth: '30px'}}
                />
                <div className="banners-head">
                  <Link to="/">
                    <span className="arcolades"> OFFERS </span>
                    <p>Stay updated pay less</p>
                  </Link>
                </div>
              </div>
              <div className="col-12 col-md-6 col-sm-6 col-lg-3 bottom-badges">
                <div className="vertical-line">&nbsp;</div>
                <img
                  src={require('../assets/single-elements/compare.png')}
                  alt="badge"
                  className="bottom-badges"
                  style={{maxWidth: '40px'}}
                />
                <div className="banners-head">
                  <Link to="/">
                    <span className="arcolades"> COMPARE </span>
                    <p>Decode the right car</p>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
// const genSlideStyle = (value) => {
//     return {
//         point: {
//             left: `calc(${value * 20}% - ${5 + 3 * value}px)`,
//         },
//         range: {
//             width: `${value * 20}%`,
//         },
//     };
// };

export default ImgAndForm;
