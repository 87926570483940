import * as React from 'react';
import QuestionOne from "./../helpers/QuestionOne";
import QuestionTwo from "./../helpers/QuestionTwo";
import Container from '@material-ui/core/Container';
import Typography from "@material-ui/core/Typography";
import MuiAlert from '@material-ui/lab/Alert';
import QuestionThree from "./../helpers/QuestionThree";
import {useState} from "react";
import SubmitInquiry from "./../helpers/SubmitInquiry";
import {trackPromise} from "react-promise-tracker";
import {makeStyles} from "@material-ui/core/styles";
import LinearStepper from "../LinearStepper";
import wallPaper from "../../assets/images/we-are-hiring.jpeg";
import {Helmet} from "react-helmet";
import CssBaseline from "@material-ui/core/CssBaseline";
import {Breadcrumb, BreadcrumbItem} from "reactstrap";
import {Link} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  heroContent: {
    padding: theme.spacing(14, 0, 6),
    backgroundImage: `url(${wallPaper})`,
    backgroundColor: 'rgba(0, 0, 0, 0.83)',
    backgroundBlendMode: 'darken',
    backgroundSize: 'cover'
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  formHeading: {
    padding: theme.spacing(0, 0, 4),
  }
}));

const q = {
  1: 'Personal Details',
  2: 'Employment History',
  3: 'References & Declaration',
  4: 'Thank you for your application',
}
function Alert(props) {
  return <MuiAlert {...props} />;
}

export default function JobApplicationPage({ openForm, handleFormClose, postcode }) {
  const classes = useStyles();
  const [sectionOne, setSectionOne] = useState({});
  const [sectionTwo, setSectionTwo] = useState({});
  const [sectionThree, setSectionThree] = useState({});
  const [error, setError] = React.useState(false);

  const [activeStep, setActiveStep] = React.useState(1);


  const handleNext = (event) => {

    setActiveStep(activeStep + 1);
  }

  const handleSubmit = (event) => {
    const userFormDetails = {};
    userFormDetails[q[1]] = sectionOne;
    userFormDetails[q[2]] = sectionTwo;
    userFormDetails[q[3]] = sectionThree;
    userFormDetails['siteID'] = 'exukonline'
    userFormDetails['task'] = 'job_application'
    const localUrl = 'https://exukonline.com/api/contact-form/'
    const fetchSubmitDetails = fetch(localUrl, {
      method: 'POST',
      body: JSON.stringify(userFormDetails),
      headers: {'Content-Type':'application/json'}
    })

    trackPromise(
      fetchSubmitDetails.then(response => {
        if (response.status === 200) {
          setSectionTwo({})
          setSectionTwo({})
          setSectionThree({})
        }
        return response
      })
    )
  }

  const handleBack = (event) => {
    setActiveStep(activeStep - 1)
  }

  const handleSection = (errors, values, section) => {
    switch (section) {
      case 1:
        setSectionOne(values);
        handleNext();
        break;
      case 2:
        setSectionTwo(values);
        handleNext();
        break;
      case 3:
        setSectionThree(values);
        handleNext();
      default:
        break;
    }
  }


  return (
    <React.Fragment>
      <Helmet>
        <meta name="description"
              content="Consulting and digital agency dedicated to delivering digital services" />
        <title>Excel Healthcare Services - Social and Domiciliary care</title>
      </Helmet>
      <CssBaseline />
      <main>
        {/* Hero unit */}
        <div className={classes.heroContent}>
          <Breadcrumb>
            <BreadcrumbItem><Link to="/">Home</Link></BreadcrumbItem>
            <BreadcrumbItem active>Application Form</BreadcrumbItem>
          </Breadcrumb>
          <Container maxWidth="sm">
            <Typography
              component="h1"
              variant="h4"
              align="center"
              // color="textPrimary"
              style={{fontWeight: 'bold', color: 'white'}}
              gutterBottom>
              <span className="page-header"> Job Application</span>
            </Typography>
          </Container>
        </div>
      </main>
      <Container maxWidth="md">
        <div className={classes.stepper}>
          <LinearStepper activeStep={(activeStep - 1)}/>
        </div>
        <div className={classes.formHeading}>
          <Typography
            variant="h5"
            align="center"
            gutterBottom
            style={{ fontWeight: "600"}}
          >
            { q[activeStep] }
          </Typography>

        </div>
        <div>
          <div>
            { error ?
              (<div style={{ padding: "16px 0px", display: ""}}>
                <Alert severity="error">
                  Please provide an answer.
                </Alert>
              </div>)
              : null
            }
            {activeStep === 1 && (
              <QuestionOne
                handleQ={handleSection}
                sectionValues={sectionOne}
              />
            )}
            {activeStep === 2 && (
              <QuestionTwo
                handleQ={handleSection}
                secectionTwoValues={sectionTwo}
                handleBack={handleBack}
              />
            )}
            {activeStep === 3 && (
              <QuestionThree
                handleQ={handleSection}
                q3Value={sectionThree}
                handleBack={handleBack}
              />
            )}
            {activeStep === 4 && (
              <SubmitInquiry
                handleSubmit={handleSubmit}
              />
            )}
          </div>
        </div>
      </Container>
    </React.Fragment>
  );
}
