import React from 'react';
import {
  Form,
  Alert,
  FormGroup,
  Label, Input
} from 'reactstrap';

class MarketingTechnicalTest extends React.Component {
  constructor(props){
    super(props)
    this.autoRequestRef = React.createRef()
    this.state = {
        userName: "",
        refNumber: "",
        userEmail: "",
        benchMarked: "",
        contactNumber: "",
        userRequest: "",
        error: false,
        message: ""
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
      this.setState({value: event.target.value});
      const target = event.target;
      const name = target.name;

      this.setState({
          [name]: target.value
      })
  }

  handleSubmit(event) {
    event.preventDefault()
    const { userName, userEmail, contactNumber, userRequest, refNumber, benchMarked } = this.state

    if (userName === "" || userEmail === "" || contactNumber === "" || userRequest === "" || refNumber === "" || benchMarked ==="") {
      this.autoRequestRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
      this.setState({error: true, message: "Missing information - Email, Name, Phone number and Request required"})
      return;
    }

    const subject = "101: CANDIDATE TECHNICAL ASSESSMENT"
    let candidateResponse = `Candidate Name: ${userName}, ref Number: ${refNumber}, Contact Number: ${contactNumber}, 
                  Q1(Purchase veh with specs) Invoice number: ${userEmail}, Q2(Purchase any item) Invoice Number: ${userRequest},
                  Q3 Benchmark product analyis: ${benchMarked}`


    this.sendRequest(process.env.REACT_APP_EMAILJS_TEMPLATEID, this.state.userName, subject,
        process.env.REACT_APP_EMAILJS_RECEIVER, candidateResponse, this.state.contactNumber)
    alert('Thank you, ' + userName + ' . You\'ve completed the technical assessment. ' +
      'One of our recruitment officers will be in touch shortly')
  }

  sendRequest(templateId, userName, userEmail, receiverEmail, userRequest, contactNumber) {
    window.emailjs.send(
      'sendgrid',
      templateId,
        {
          userName,
          userEmail,
          receiverEmail,
          contactNumber,
          userRequest
        })
        .then(res => {
          this.setState({
              userName: "",
              userEmail: "",
              refNumber: "",
              contactNumber: "",
              userRequest: "",
              benchMarked: "",
              error: false,
              message: ""})
        })
        .catch(err => console.error('Failed to send feedback. Error: ', err))
  }

  render() {
  const { message } = this.state;
  debugger;

  return <div>

    <div>
      <section className="gen-section  filter_space">
        <div className="container mobile_padding">
          <div className="row">
            <div className="col-lg-12 col-sm-12">
              <div className="static-info-page static-info-padding" style={{paddingBottom: '60px'}}>
                <div className="" style={{ textAlign: 'center' }}>
                  <p className="how-to-steps" style={{fontSize: '16px'}}>Sales And Marketing Technical Assessment</p>
                </div>
              <hr className="fancy-line"/>
                <div className="col-lg-6 col-sm-12 offset-lg-3" style={{padding: '10px'}}>
                  <div className="page-information" style={{fontSize: '14px'}}>
                    <p>
                      This is a technical assessment. <br />*Purchase means that you should complete step 1 from <a href="/how-to-buy" className="a-links"> how to buy guide </a>only. Do not complete step 2. You are NOT requested to deposit any funds/monies in any account.
                    </p>
                    <p>
                      Please note: In order to checkout the items in the basket you will need to make a request for shipping quotation. Please allow up to an hour response time if the request is made between Mon-Friday 9am-5pm UK time.
                    </p>
                  </div>
                  <hr />
                  <Form onSubmit={this.handleSubmit} style={{fontSize: '18px'}}>
                    <div ref={this.autoRequestRef}>
                      {message &&
                      <Alert color="danger">
                        {message}
                      </Alert>}
                    </div>
                    <FormGroup>
                      <Label  for="refNumber" className="form-input">Ref Number</Label>
                      <Input
                        name="refNumber"
                        type="text"
                        value={this.state.refNumber}
                        onChange={this.handleChange}
                        placeholder="Your Ref Number"
                        maxLength={255}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for="userName" className="form-input">Name</Label>
                      <Input
                        name="userName"
                        type="text"
                        value={this.state.userName}
                        onChange={this.handleChange}
                        placeholder="Your full name"
                        maxLength={255}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for="contactNumber" className="form-input">Contact Number</Label>
                      <Input
                        name="contactNumber"
                        type="text"
                        value={this.state.contactNumber}
                        onChange={this.handleChange}
                        placeholder="Phone number incl country code"
                        maxLength={26}
                    />
                    </FormGroup>
                    <FormGroup>
                      <Label for="userEmail" className="form-input">*Purchase a diesel vehicle under the age of 10years. Submit your invoice number below</Label>
                      <Input
                        name="userEmail"
                        type="text"
                        value={this.state.userEmail}
                        onChange={this.handleChange}
                        placeholder="Invoice number #"
                        maxLength={255}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for="userRequest" className="form-input">*Purchase any other item of your choice. The item should not be in the vehicle, electronics or spare parts category. Submit your invoice number below</Label>
                      <Input
                        name="userRequest"
                        type="text"
                        value={this.state.userRequest}
                        onChange={this.handleChange}
                        placeholder="Invoice number #"
                        maxLength={255}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for="benchMarked" className="form-input">Benchmark any local (available in your country) product to
                        any product available to buy on Ex UK Online (focus on the price competitiveness). For the local product: Provide details on the price and where it's available, For the Ex UK product: Provide the Item Title and Item code</Label>
                      <Input
                        name="benchMarked"
                        type="textarea"
                        value={this.state.benchMarked}
                        onChange={this.handleChange}
                        placeholder="Your evaluation here"
                        maxLength={2500}
                        style={{minHeight: '100px'}}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Input type="submit" name="signup" id="signup"  style={{color: 'white', fontFamily: 'Roboto Condensed'}} className="btn btn-primary" value="Submit Assessment" />
                    </FormGroup>
                  </Form>
                  <hr/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>

  </div>;
  }
}

export default MarketingTechnicalTest;