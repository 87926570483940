import * as React from 'react'
import { AvForm, AvField, AvRadioGroup, AvRadio } from 'availity-reactstrap-validation';
import { Button, FormGroup } from 'reactstrap';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {useEffect} from "react";

export default function QuestionOne({handleQ, sectionValues}) {

  const handleSubmit = (event, errors, values) => {

    const criticalErrors = ['position', 'location', 'name', 'surname', 'address',
      'email', 'contact', 'driving', 'endorsement'];
    const includesErrors = errors.some((element) => criticalErrors.includes(element));
    if (includesErrors) {
      return;
    }
    handleQ(errors, values, 1)
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <div>
      <AvForm onSubmit={handleSubmit} model={sectionValues} className="my-custom-form">
        {/*Position Applied For*/}
        <div className="form-row">
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <div>
                <div className="form-group">
                  <label htmlFor="position">Position Applied For</label>
                  <AvField
                    name="position"
                    id="position"
                    type="text"
                    errorMessage="Invalid Position"
                    validate={{
                      required: { value: true },
                      minLength: { value: 4 },
                      maxLength: { value: 40 },
                    }}
                    className="custom-input"
                  />
                </div>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div>
                <div className="form-group">
                  <label htmlFor="location">Location</label>
                  <AvField
                    name="location"
                    id="location"
                    type="text"
                    errorMessage="Invalid location"
                    validate={{
                      required: { value: true },
                      pattern: { value: '^[A-Za-z0-9]+$' },
                      minLength: { value: 3 },
                      maxLength: { value: 16 },
                    }}
                    className="custom-input"
                  />
                </div>
              </div>
            </Grid>
          </Grid>
        </div>

        {/*Where did you hear about the vacancy*/}
        <div className="form-row">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div className="form-group">
                <label htmlFor="vacancy">Where did you hear about the vacancy</label>
                <AvField
                  name="vacancy"
                  id="vacancy"
                  type="text"
                  errorMessage="Invalid"
                  validate={{
                    required: { value: true },
                    minLength: { value: 3 },
                    maxLength: { value: 45 },
                  }}
                  className="custom-input"
                />
              </div>
            </Grid>
          </Grid>
        </div>

        {/*Title*/}
        <div className="form-row">
          <Grid container spacing={2}>
            <Grid item xs={2}>
              <div className="form-group">
                {/* With select and AvField */}
                <AvField type="select" name="name.title" label="Title">
                  <option>Mr</option>
                  <option>Miss</option>
                  <option>Mrs</option>
                  <option>Ms</option>
                  <option>Dr</option>
                </AvField>
              </div>
            </Grid>
            <Grid item xs={5}>
              <div className="form-group">
                <label htmlFor="name">Forename(s)</label>
                <AvField
                  name="name"
                  id="name"
                  type="text"
                  errorMessage="Invalid Name"
                  validate={{
                    required: { value: true },
                    minLength: { value: 2 },
                    maxLength: { value: 44 },
                  }}
                  className="custom-input"
                />
              </div>
            </Grid>
            <Grid item xs={5}>
              <div className="form-group">
                <label htmlFor="surname">Surname</label>
                <AvField
                  name="surname"
                  id="surname"
                  type="text"
                  errorMessage="Invalid Surname"
                  validate={{
                    required: { value: true },
                    minLength: { value: 2 },
                    maxLength: { value: 44 },
                  }}
                  className="custom-input"
                />
              </div>

            </Grid>

          </Grid>
        </div>

        {/*Address*/}
        <div className="form-row">
          <Grid container spacing={2}>
            <Grid item={12} style={{width: "100%"}}>
              <div className="form-group">
                <label htmlFor="address">Physical Address</label>
                <AvField
                  name="address"
                  id="address"
                  type="text"
                  errorMessage="Invalid"
                  validate={{
                    required: { value: true },
                    minLength: { value: 3 },
                    maxLength: { value: 100 },
                  }}
                  className="custom-input"
                />
              </div>
            </Grid>
          </Grid>
        </div>

        {/*Email and Contact*/}
        <div className="form-row">
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <div>
                <div className="form-group">
                  <label htmlFor="email">Email</label>
                  <AvField
                    name="email"
                    id="email"
                    type="text"
                    errorMessage="Invalid address"
                    validate={{
                      required: { value: true },
                      minLength: { value: 6 },
                      maxLength: { value: 200 },
                    }}
                    className="custom-input"
                  />
                </div>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div>
                <div className="form-group">
                  <label htmlFor="contact">Contact Number</label>
                  <AvField
                    name="contact"
                    id="contact"
                    type="text"
                    errorMessage="Invalid Contact Number"
                    validate={{
                      required: { value: true },
                      pattern: { value: '^[0-9]+$' },
                      minLength: { value: 3 },
                      maxLength: { value: 16 },
                    }}
                    className="custom-input"
                  />
                </div>
              </div>
            </Grid>
          </Grid>
        </div>

        {/*Driving and Endorsement*/}
        <div className="form-row">
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <AvRadioGroup className="fieldset-custom"
                            inline name="driving"
                            label="Do you have a full driving licence?"
                            required>
                <div className="inline-radio">
                  <AvRadio label="Yes" value="yes" />
                  <AvRadio label="No" value="No" />
                </div>
              </AvRadioGroup>
            </Grid>
            <Grid item xs={6}>
              <AvRadioGroup className="fieldset-custom"
                            inline name="endorsement"
                            label="Do you have any current endorsement?"
                            required>
                <div className="inline-radio">
                  <AvRadio label="Yes" value="yes" />
                  <AvRadio label="No" value="No" />
                </div>
              </AvRadioGroup>
            </Grid>
          </Grid>
        </div>

        {/*Permit and NI*/}
        <div className="form-row">
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <AvRadioGroup className="fieldset-custom"
                            inline name="permit"
                            label="Do you require a Work Permit to work in the UK?"
                            required>
                <div className="inline-radio">
                  <AvRadio label="Yes" value="yes" />
                  <AvRadio label="No" value="No" />
                </div>
              </AvRadioGroup>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label htmlFor="ni">National Insurance Number</label>
                <AvField
                  name="ni"
                  id="ni"
                  type="text"
                  errorMessage="Invalid"
                  validate={{
                    required: { value: true },
                    pattern: { value: '^[A-Za-z0-9]+$' },
                    minLength: { value: 3 },
                    maxLength: { value: 16 },
                  }}
                  className="custom-input"
                />
              </div>
            </Grid>
          </Grid>
        </div>

        {/*Education Section*/}
        <div style={{ padding: "16px 0px 0px 0px"}}>
          <div>
            <Typography>
              <h3>Education</h3>
            </Typography>
          </div>
          <div style={{ padding: "2px 0px 20px 0px"}}>
            <Typography>
              Please tell us about your education. List any qualifications gained. Any further education?
            </Typography>
          </div>
        </div>


        {/*Education header 1*/}
        <div className="form-row">
          <Grid container spacing={2}>
            <Grid item={12} style={{width: "100%"}}>
              <div className="form-group">
                <label htmlFor="education1">Name of School / College / University:</label>
                <AvField
                  name="education1"
                  id="education1"
                  type="text"
                  errorMessage="Invalid"
                  validate={{
                    required: { value: true },
                    minLength: { value: 3 },
                    maxLength: { value: 72 },
                  }}
                  className="custom-input"
                />
              </div>
            </Grid>
          </Grid>
        </div>

        {/*Education period 1*/}
        <div className="form-row">
          <Grid container spacing={2}>
            <Grid item xs={6} >
              <div className="form-group">
                <label htmlFor="dateFrom1">Date From:</label>
                <AvField
                  id="dateFrom1"
                  name="dateFrom1"
                  className="form-control" // You can specify the number of rows you want
                  required
                ></AvField>
              </div>
            </Grid>
            <Grid item xs={6} >
              <div className="form-group">
                <label htmlFor="dateTo1">Date To:</label>
                <AvField
                  id="dateTo1"
                  name="dateTo1"
                  className="form-control" // You can specify the number of rows you want
                  required
                ></AvField>
              </div>
            </Grid>
          </Grid>
        </div>

        {/*Education detail 1*/}
        <div className="form-row">
          <Grid container spacing={2} >
            <Grid item xs={6} >
              <div className="form-group">
                <label htmlFor="education1Address">Address</label>
                <AvField
                  style={{width: "100%"}}
                  type="textarea"
                  name="education1Address"
                  rows="4" // Set the number of rows you want to display
                  placeholder="Address here..."
                  required
                />
              </div>
            </Grid>
            <Grid item xs={6} >
              <div className="form-group">
                <label htmlFor="education1Qualification">Qualifications</label>
                <AvField
                  style={{width: "100%"}}
                  type="textarea"
                  name="education1Qualification"
                  rows="4" // Set the number of rows you want to display
                  placeholder="Text here..."
                  required
                />
              </div>
            </Grid>
          </Grid>
        </div>

        <hr style={{border: "2px solid #000", margin: "24px 0px 24px 0px"}}/>

        {/*Education header 2*/}
        <div className="form-row">
          <Grid container spacing={2}>
            <Grid item={12} style={{width: "100%"}}>
              <div className="form-group">
                <label htmlFor="education2">Name of School / College / University:</label>
                <AvField
                  name="education2"
                  id="education2"
                  type="text"
                  errorMessage="Invalid"
                  validate={{
                    required: { value: true },
                    minLength: { value: 3 },
                    maxLength: { value: 72 },
                  }}
                  className="custom-input"
                />
              </div>
            </Grid>
          </Grid>
        </div>

        {/*Education period 2*/}
        <div className="form-row">
          <Grid container spacing={2}>
            <Grid item xs={6} >
              <div className="form-group">
                <label htmlFor="dateFrom2">Date From:</label>
                <AvField
                  id="dateFrom2"
                  name="dateFrom2"
                  className="form-control"
                  required
                ></AvField>
              </div>
            </Grid>
            <Grid item xs={6} >
              <div className="form-group">
                <label htmlFor="dateTo1">Date To:</label>
                <AvField
                  id="dateTo2"
                  name="dateTo2"
                  className="form-control"
                  required
                ></AvField>
              </div>
            </Grid>
          </Grid>
        </div>

        {/*Education detail 2*/}
        <div className="form-row">
          <Grid container spacing={2} >
            <Grid item xs={6} >
              <div className="form-group">
                <label htmlFor="education2Address">Address</label>
                <AvField
                  style={{width: "100%"}}
                  type="textarea"
                  name="education2Address"
                  rows="4" // Set the number of rows you want to display
                  placeholder="Address here..."
                  required
                />
              </div>
            </Grid>
            <Grid item xs={6} >
              <div className="form-group">
                <label htmlFor="education2Qualification">Qualifications</label>
                <AvField
                  style={{width: "100%"}}
                  type="textarea"
                  name="education2Qualification"
                  rows="4" // Set the number of rows you want to display
                  placeholder="Text here..."
                  required
                />
              </div>
            </Grid>
          </Grid>
        </div>

        {/*Any other training or qualifications:*/}
        <div className="form-row">
          <Grid container spacing={2} >
            <Grid item xs={12} >
              <div className="form-group">
                <label htmlFor="anyOtherTraining">If you have undertaken any training or voluntary work to improve your employment prospects, please give details below.</label>
                <AvField
                  style={{width: "100%"}}
                  type="textarea"
                  name="anyOtherTraining"
                  rows="4" // Set the number of rows you want to display
                  placeholder="Text here..."
                  required
                />
              </div>
            </Grid>
          </Grid>
        </div>

        {/*Continue button*/}
        <div style={{ padding: "20px" }}>
          <Grid container>
            <Grid item xs={6}>
            </Grid>
            <Grid item xs={6} style={{ textAlign: "right"}}>
              <FormGroup>
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  className="mui-buttons">
                  Continue</Button>
              </FormGroup>
            </Grid>
          </Grid>
        </div>
      </AvForm>

    </div>
  )
}